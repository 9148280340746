import { ChangeEvent } from "react";

import Button from "basics/button";

import {
  PartialPaymentMethodInfo,
  SetPaymentMethodInfo,
} from "../../duck/types";
import PaymentTypesChooser from "../../../components/payment-types-chooser";

import modalClasses from "../../styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
  setPaymentMethodInfo: SetPaymentMethodInfo;
  paymentMethodInfo: PartialPaymentMethodInfo;
}

const ChoosePaymentMethod = ({
  nextStep,
  setPaymentMethodInfo,
  paymentMethodInfo,
}: Props) => {
  const onChangePaymentMethods = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethodInfo(prevPaymentMethodInfo => ({
      ...prevPaymentMethodInfo,
      paymentCategories: prevPaymentMethodInfo.paymentCategories.map(
        paymentCategory => {
          if (paymentCategory.name === e.target.name) {
            return {
              ...paymentCategory,
              isSelected: e.target.checked,
            };
          }

          return paymentCategory;
        },
      ),
    }));
  };

  const setInitialPaymentTypes = (paymentCategories: PaymentCategory[]) => {
    setPaymentMethodInfo(prevPaymentMethodInfo => ({
      ...prevPaymentMethodInfo,
      paymentCategories: paymentCategories.map(paymentCategory => ({
        ...paymentCategory,
        isSelected: true,
      })),
    }));
  };

  return (
    <form
      className={modalClasses.form}
      onSubmit={event => {
        event.preventDefault();
        nextStep();
      }}
    >
      <h2 className={modalClasses.title}>
        Select your preferred payment types
      </h2>
      <PaymentTypesChooser
        regionId={
          paymentMethodInfo.nestedRegion?.id || paymentMethodInfo.region.id
        }
        paymentTypes={paymentMethodInfo.paymentCategories}
        setInitialOptions={setInitialPaymentTypes}
        onChange={onChangePaymentMethods}
      />
      <Button
        type="submit"
        isBlock
        themeName="primary"
        disabled={paymentMethodInfo.paymentCategories.every(
          ({ isSelected }) => !isSelected,
        )}
        className={modalClasses.nextButton}
      >
        Continue
      </Button>
    </form>
  );
};

export default ChoosePaymentMethod;
