import { FC } from "react";

import { serviceOptions, SearchSelect } from "basics/select";
import ErrorScreen from "components/error";
import Button from "basics/button";

import { useCurrenciesForRegion } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  regionId: Region["id"];
  currencies: CurrencyWithDefault[];
  selectedCurrency: CurrencyWithDefault;
  onChange: (currency: CurrencyWithDefault) => void;
  setInitialCurrencies: (currencies: CurrencyWithDefault[]) => void;
}

const CurrencyChooser: FC<Props> = ({
  regionId,
  selectedCurrency,
  onChange,
  currencies,
  setInitialCurrencies,
}) => {
  const { isLoading, error, loadCurrenciesForRegion } = useCurrenciesForRegion({
    regionId,
    currencies,
    setInitialCurrencies,
  });

  let content: JSX.Element;
  if (error) {
    content = (
      <ErrorScreen>
        <Button
          className={classes.tryToReloadButton}
          themeName="primary"
          onClick={() => {
            loadCurrenciesForRegion();
          }}
        >
          Try to reload
        </Button>
      </ErrorScreen>
    );
  } else {
    content = (
      <SearchSelect
        placeholder="Search for currency"
        value={selectedCurrency}
        onChange={onChange}
        options={currencies}
        isBlock
        isLoading={isLoading}
        renderButton={serviceOptions.Button}
        renderOption={serviceOptions.Option}
        onFilter={(query, { value }) =>
          value.fullName.toLowerCase().includes(query) ||
          value.name.toLowerCase().includes(query)
        }
      />
    );
  }

  return <div className={classes.wrapper}>{content}</div>;
};

export default CurrencyChooser;
