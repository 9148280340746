import { FC, useState } from "react";

import { Modal } from "components/dialog";

import ChooseCountry from "./components/choose-country";
import ChoosePaymentMethod from "./components/choose-payment-method";
import ChooseCurrency from "./components/choose-currency";

import {
  PartialPaymentMethodInfo,
  SetPaymentMethodInfo,
  Steps,
} from "./duck/types";

interface Props {
  initialStep?: Steps;
}

const STEP_COMPONENTS = [ChooseCountry, ChoosePaymentMethod, ChooseCurrency];

const AddPaymentMethodModal: FC<Props> = ({
  initialStep = Steps.chooseCountry,
}) => {
  const [paymentMethodInfo, setPaymentMethodInfo] =
    useState<PartialPaymentMethodInfo>({
      paymentCategories: [],
      currencies: [],
      selectedCurrency: null,
      region: null,
      nestedRegion: null,
    });
  const [step, setStep] = useState(initialStep);

  const StepComponent: FC<{
    nextStep: VoidFunction;
    setPaymentMethodInfo: SetPaymentMethodInfo;
    paymentMethodInfo: PartialPaymentMethodInfo;
  }> = STEP_COMPONENTS[step];

  return (
    <Modal>
      <Modal.StepHeader
        onBack={
          step !== Steps.chooseCountry
            ? () => setStep(current => current - 1)
            : null
        }
      >
        Step {step + 1} of 3
      </Modal.StepHeader>
      <StepComponent
        nextStep={() => setStep(current => current + 1)}
        paymentMethodInfo={paymentMethodInfo}
        setPaymentMethodInfo={setPaymentMethodInfo}
      />
    </Modal>
  );
};

export default AddPaymentMethodModal;
