import { useEffect, useState } from "react";

import servicesAPI from "api/services";

import { getPaymentCategories } from "operations/payment";

import { LocalGroupedPaymentType } from "../../../duck/types";

interface Args {
  regionId: Region["id"];
  paymentTypes: LocalGroupedPaymentType[];
  setInitialOptions: (paymentTypes: LocalGroupedPaymentType[]) => void;
}

export const usePaymentTypesForRegion = ({
  regionId,
  paymentTypes,
  setInitialOptions,
}: Args) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPaymentTypesForRegion = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const paymentTypes = await servicesAPI.fetchPaymentTypesForRegion(
        regionId,
      );
      setInitialOptions(getPaymentCategories(paymentTypes));
    } catch (error) {
      setError(error.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (paymentTypes?.length) {
      return;
    }

    loadPaymentTypesForRegion();
  }, []);

  return { isLoading, error, loadPaymentTypesForRegion };
};
