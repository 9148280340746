import { PropsWithChildren, useEffect } from "react";
import joinClassNames from "classnames";

import CloseIcon from "icons/close.svg?react";

import useChangePathname from "hooks/use-change-pathname";
import useWindowSizes from "hooks/use-window-sizes";

import { getCSSVariables } from "selectors/common";

import Button from "basics/button";

import Scrollbar from "components/scrollbar";

import Overlay from "../components/overlay";
import Header from "./components/header";
import Body from "./components/body";
import Footer from "./components/footer";

import { Placement } from "./duck/types";
import { useClickOutside, useDialog } from "../duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props extends Required<PropsWithChildren> {
  isClosable?: boolean;
  onClosed?: VoidFunction;
  className?: string;
  classNames?: Partial<{ drawer: string }>;
  placement?: Placement;
}

const Drawer = ({
  children,
  isClosable = true,
  onClosed,
  className,
  classNames = {},
  placement = "right",
}: Props) => {
  const { isOpened, animationDelay, resolve } = useDialog();
  const { isMobileWidth } = useWindowSizes();

  const closeDrawer = () => resolve(null);

  useChangePathname(closeDrawer);

  const { contentRef, overlayProps } = useClickOutside(closeDrawer);

  useEffect(() => onClosed, []);

  const isBottomPlacement = placement === "bottom";

  return (
    <Overlay
      className={className}
      style={getCSSVariables({ animationDelay: `${animationDelay}ms` })}
      {...overlayProps}
    >
      <div
        className={joinClassNames(
          classes.drawer,
          classes[`${placement}Drawer`],
          classNames.drawer,
        )}
        role="dialog"
        aria-modal="true"
        data-opened={isOpened}
        data-placement={placement}
      >
        {isClosable && (
          <Button
            className={classes.closeButton}
            themeName={isBottomPlacement || isMobileWidth ? "ghost" : "primary"}
            size={isBottomPlacement || isMobileWidth ? "sm" : "lg"}
            isIconOnly
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.closeIcon} />
          </Button>
        )}
        <Scrollbar
          contentProps={{
            elementRef: element => {
              contentRef.current = element;
            },
            style: { display: "flex", flexDirection: "column" },
          }}
          translateContentSizeYToHolder={isBottomPlacement}
          translateContentSizeXToHolder={!isBottomPlacement}
          trackYOffset={24}
          className={classes.content}
        >
          {children}
        </Scrollbar>
      </div>
    </Overlay>
  );
};

Drawer.Header = Header;
Drawer.Body = Body;
Drawer.Footer = Footer;

export default Drawer;
