import { useContext } from "react";

import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

import { accountSelectors, accountThunks } from "store/account";

import { ProfileDrawerContext } from "./constants";

export const useConnect = () => {
  const account = useSelector(accountSelectors.selectAccount);
  const isSigning = useSelector(state => state.account.isSigning);
  const currency = useSelector(accountSelectors.selectCurrency);
  const isCurrenciesLoaded = useSelector(state => state.currencies.isLoaded);

  const dispatch = useDispatch();

  return {
    account,
    signOut: () => dispatch(accountThunks.signOut()),
    isSigning,
    currency,
    isCurrenciesLoaded,
  };
};

export const useProfileDrawer = () => useContext(ProfileDrawerContext);
