import { useEffect, useMemo, useState } from "react";

import servicesAPI from "api/services";

import useSelector from "hooks/redux/use-selector";

interface Args {
  regionId: Region["id"];
  currencies: Currency[];
  setInitialCurrencies: (currencies: Currency[]) => void;
}

export const useCurrenciesForRegion = ({
  regionId,
  currencies,
  setInitialCurrencies,
}: Args) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const paymentMethodEntities = useSelector(
    state => state.account.paymentMethods.entities,
  );

  const excludedCurrenciesMap = useMemo(() => {
    const paymentMethods = Object.values(paymentMethodEntities);

    return paymentMethods.reduce((memo, paymentMethod) => {
      if (regionId === paymentMethod.region.id) {
        memo[paymentMethod.currency.id] = true;
      }
      return memo;
    }, {});
  }, [paymentMethodEntities]);

  const loadCurrenciesForRegion = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const currencies = await servicesAPI.fetchCurrenciesForRegion(regionId);
      const initialCurrencies = [];

      currencies.forEach(currency => {
        if (!excludedCurrenciesMap[currency.id]) {
          initialCurrencies.push(currency);
        }
      });

      setInitialCurrencies(initialCurrencies);
    } catch (error) {
      setError(error.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (currencies.length) {
      return;
    }

    loadCurrenciesForRegion();
  }, []);

  return { isLoading, error, loadCurrenciesForRegion };
};
