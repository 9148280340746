import { LocalGroupedPaymentType } from "./types";

export const getSelectedPaymentCategories = (
  paymentTypes: LocalGroupedPaymentType[],
) => {
  const options: PaymentCategory[] = [];

  paymentTypes.forEach(({ isSelected, ...paymentType }) => {
    if (isSelected) {
      options.push(paymentType);
    }
  });

  return options;
};
