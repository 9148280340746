import { type EntityId } from "@reduxjs/toolkit";

import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

import { accountThunks } from "store/account";

export const useConnect = (id: EntityId) => {
  const paymentMethod = useSelector(
    state => state.account.paymentMethods.entities[id],
  );
  const paymentMethodStatus = useSelector(
    state => state.account.paymentMethodStatuses[id],
  );
  const isDisabledDelete = useSelector(
    state => state.account.paymentMethods.ids.length === 1,
  );

  const dispatch = useDispatch();

  const removePaymentMethod = (id: PaymentMethod["id"]) => {
    dispatch(accountThunks.removePaymentMethod(id));
  };

  return {
    isDisabledDelete,
    removePaymentMethod,
    paymentMethod,
    paymentMethodStatus,
  };
};
