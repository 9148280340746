import { type EntityId } from "@reduxjs/toolkit";

import MenuVerticalIcon from "icons/menu-vertical.svg?react";
import EditIcon from "icons/edit.svg?react";
import Trash24Icon from "icons/trash24.svg?react";

import Button from "basics/button";
import Loader from "basics/loader";
import Dropdown, { Item } from "basics/dropdown";

import CircledIcon, { PaymentMethodIcon } from "components/icon";

import { PaymentMethodStatuses } from "types/payment";

import { openEditPaymentMethodModal } from "modules/payment-method-modals";

import { useConnect } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  id: EntityId;
}

const PaymentMethodCard = ({ id }: Props) => {
  const {
    removePaymentMethod,
    paymentMethod,
    paymentMethodStatus,
    isDisabledDelete,
  } = useConnect(id);

  const { region, currency, categories } = paymentMethod;
  const isEditingOrRemoving =
    paymentMethodStatus === PaymentMethodStatuses.editing ||
    paymentMethodStatus === PaymentMethodStatuses.removing;

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <CircledIcon src={region.parentRegion?.icon || region.icon} />
        <div className={classes.texts}>
          <p className={classes.countryName}>
            {region.parentRegion
              ? `${region.parentRegion.name}, ${region.name}`
              : region.name}
          </p>
          <span className={classes.paymentText}>{currency.name}</span>
        </div>
        <div className={classes.paymentTypesWrapper}>
          {categories.map(({ name, icon: categoryIcon, index }) => (
            <PaymentMethodIcon
              key={index}
              title={name}
              size="sm"
              src={categoryIcon}
            />
          ))}
        </div>
        <Dropdown
          onClick={event => event.preventDefault()}
          placement="bottom_end"
          menuComponent={
            <>
              <Item
                onClick={() => {
                  openEditPaymentMethodModal(id);
                }}
              >
                <EditIcon />
                Edit
              </Item>
              <Item
                disabled={isDisabledDelete}
                onClick={() => {
                  removePaymentMethod(id);
                }}
              >
                <Trash24Icon className={classes.trash24Icon} />
                Delete
              </Item>
            </>
          }
        >
          <Button
            size="xs"
            isIconOnly
            themeName="ghost"
            disabled={isEditingOrRemoving}
          >
            {isEditingOrRemoving ? (
              <Loader
                themeName={
                  paymentMethodStatus === PaymentMethodStatuses.editing
                    ? "default"
                    : "destructive"
                }
                size="sm"
              />
            ) : (
              <MenuVerticalIcon className={classes.menuVerticalIcon} />
            )}
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
