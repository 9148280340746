import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Modal } from "components/dialog";

import Input from "basics/input";
import Button from "basics/button";

import { useConnect } from "./duck/hooks";
import { MAX_ADDRESS_LENGTH } from "./duck/constants";
import { FormValues } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  id: Address["id"];
}

const AddressModal: FC<Props> = ({ id }) => {
  const {
    initialAddressName,
    address,
    onUpdateAddress,
    onDeleteAddress,
    isDeleting,
  } = useConnect(id);

  const {
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting: isEditing, errors, isValid },
  } = useForm<FormValues>({
    defaultValues: { addressName: initialAddressName || "" },
  });

  const isDisabled = isEditing || isDeleting;

  const submitHandler: SubmitHandler<FormValues> = ({ addressName }) => {
    if (addressName.length > MAX_ADDRESS_LENGTH) {
      return setError(
        "addressName",
        {
          message: `Address name should not contain more than ${MAX_ADDRESS_LENGTH} symbols`,
        },
        { shouldFocus: true },
      );
    }

    return onUpdateAddress(addressName);
  };

  return (
    <Modal className={classes.modal}>
      <Modal.Header>Manage address</Modal.Header>
      <form
        className={classes.contentForm}
        onSubmit={handleSubmit(submitHandler)}
      >
        <Input
          className={classes.addressInput}
          label="Wallet address"
          readOnly
          size="md"
          value={address}
        />
        <Input
          size="md"
          autoComplete="off"
          autoFocus
          label="Set address name"
          placeholder="Address name"
          error={errors?.addressName?.message}
          {...register("addressName", {
            required: true,
          })}
        />
        <div className={classes.buttonToolbar}>
          <Button
            className={classes.submitButton}
            themeName="primary"
            type="submit"
            isBlock
            isLoading={isEditing}
            disabled={isDisabled || !isValid}
          >
            Save changes
          </Button>
          <Button
            className={classes.deleteAddressButton}
            themeName="ghost"
            disabled={isDisabled}
            isLoading={isDeleting}
            onClick={onDeleteAddress}
          >
            Delete address
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddressModal;
