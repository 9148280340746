import { FC, ChangeEvent } from "react";

import Checkbox, { Tick } from "basics/checkbox";
import Button from "basics/button";

import ErrorScreen from "components/error";
import { PaymentMethodIcon } from "components/icon";

import Skeleton from "./components/skeleton";
import { usePaymentTypesForRegion } from "./duck/hooks";
import { LocalGroupedPaymentType } from "../../duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  regionId: Region["id"];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  paymentTypes: LocalGroupedPaymentType[];
  setInitialOptions: (paymentTypes: LocalGroupedPaymentType[]) => void;
}

const PaymentTypesChooser: FC<Props> = ({
  regionId,
  paymentTypes,
  setInitialOptions,
  onChange,
}) => {
  const { isLoading, error, loadPaymentTypesForRegion } =
    usePaymentTypesForRegion({
      regionId,
      paymentTypes,
      setInitialOptions,
    });

  let content: JSX.Element;
  if (isLoading) {
    content = (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  } else if (error) {
    content = (
      <ErrorScreen>
        <Button
          themeName="primary"
          onClick={() => {
            loadPaymentTypesForRegion();
          }}
          className={classes.tryToReloadButton}
        >
          Try to reload
        </Button>
      </ErrorScreen>
    );
  } else {
    content = (
      <>
        {paymentTypes.map(
          ({
            name: category,
            isSelected,
            icon: categoryIcon,
            description: categoryDescription,
          }) => (
            <Checkbox
              key={category}
              name={category}
              checked={isSelected}
              onChange={onChange}
              className={classes.checkbox}
              classNames={{
                label: classes.label,
                input: classes.input,
              }}
            >
              <div className={classes.contentWrapper}>
                <PaymentMethodIcon size="lg" src={categoryIcon} />
                <div className={classes.textWrapper}>
                  <p className={classes.title}>{category}</p>
                  <p className={classes.description}>{categoryDescription}</p>
                </div>
              </div>
              <Tick />
            </Checkbox>
          ),
        )}
      </>
    );
  }

  return <div className={classes.wrapper}>{content}</div>;
};

export default PaymentTypesChooser;
