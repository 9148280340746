import { Fragment, useMemo } from "react";

import Loader from "basics/loader";
import Button from "basics/button";

import EmptyScreen from "components/empty-screen";
import ErrorScreen from "components/error";
import { Drawer } from "components/dialog";

import globalClasses from "styles/classes.module.scss";

import { useConnect } from "./duck/hooks";
import Address from "./components/address";
import { getGroupedAddresses } from "./duck/selectors";
import { useProfileDrawer } from "../../duck/hooks";

import profileDrawerClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Addresses = () => {
  const { addressIds, addressEntities, loadAddresses, isLoading, error } =
    useConnect();

  const { changeScreen } = useProfileDrawer();

  const groupedAddresses = useMemo(
    () =>
      getGroupedAddresses({
        addressIds,
        addressEntities,
      }),
    [addressIds.length],
  );

  let content: JSX.Element;
  if (isLoading) {
    content = <Loader className={classes.loader} />;
  } else if (error) {
    content = (
      <ErrorScreen className={classes.errorScreen}>
        <Button
          className={classes.tryToReloadButton}
          themeName="primary"
          onClick={() => {
            loadAddresses();
          }}
        >
          Try to reload
        </Button>
      </ErrorScreen>
    );
  } else if (!groupedAddresses.length) {
    content = (
      <EmptyScreen
        title="No addresses yet"
        subtitle="You'll find your addresses here"
      />
    );
  } else {
    content = (
      <ul className={profileDrawerClasses.list} role="list">
        {groupedAddresses.map(({ title, options }) => (
          <Fragment key={title}>
            <li role="listitem" className={profileDrawerClasses.listTitle}>
              {title}
            </li>
            {options.map(id => (
              <Address key={id} id={id} />
            ))}
          </Fragment>
        ))}
      </ul>
    );
  }

  return (
    <>
      <Drawer.Header onBack={() => changeScreen("main")}>
        <h2 className={globalClasses.gradientTitle}>Addresses</h2>
      </Drawer.Header>
      <Drawer.Body className={classes.drawerContainer}>{content}</Drawer.Body>
    </>
  );
};

export default Addresses;
